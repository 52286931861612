import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 3264.000000 3264.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,3264.000000) scale(0.100000,-0.100000)">

<path d="M15905 30279 c-700 -25 -1395 -97 -2030 -213 -44 -8 -109 -19 -145
-26 -71 -12 -167 -31 -275 -55 -38 -9 -99 -22 -135 -29 -65 -14 -165 -37 -270
-61 -30 -7 -118 -29 -195 -50 -386 -101 -499 -134 -835 -240 -111 -35 -369
-124 -555 -192 -178 -65 -558 -217 -720 -288 -79 -35 -209 -94 -360 -163 -378
-173 -1039 -533 -1448 -789 -680 -425 -1492 -1039 -2002 -1514 -33 -30 -98
-91 -145 -134 -243 -224 -644 -634 -891 -910 -711 -796 -1384 -1754 -1854
-2640 -26 -49 -63 -119 -83 -155 -44 -82 -231 -456 -272 -544 -16 -36 -43 -94
-59 -128 -56 -122 -140 -315 -206 -473 -37 -88 -73 -173 -80 -190 -21 -48
-133 -348 -182 -485 -80 -229 -107 -310 -214 -655 -91 -294 -217 -786 -288
-1125 -85 -405 -124 -632 -191 -1120 -85 -624 -129 -1464 -110 -2105 21 -679
71 -1257 155 -1785 40 -250 46 -290 60 -365 9 -44 20 -107 26 -140 36 -211
146 -688 237 -1030 120 -451 314 -1035 497 -1495 466 -1177 1072 -2257 1839
-3275 621 -826 1373 -1619 2156 -2274 74 -62 144 -120 155 -129 134 -114 520
-407 763 -581 390 -278 956 -635 1287 -811 44 -23 103 -55 130 -70 142 -78
209 -113 425 -220 129 -65 285 -140 345 -168 61 -27 142 -64 180 -82 108 -49
123 -56 251 -110 208 -90 665 -267 827 -321 34 -11 112 -38 172 -59 61 -21
144 -48 185 -60 41 -12 95 -29 119 -37 185 -60 694 -200 886 -243 50 -11 106
-25 125 -30 37 -10 129 -31 220 -50 30 -7 84 -18 120 -26 36 -8 88 -19 115
-24 28 -5 86 -16 130 -25 418 -81 1069 -166 1495 -195 80 -6 201 -15 270 -20
421 -33 1570 -16 2000 30 52 5 163 17 245 26 314 33 710 88 930 128 44 8 109
19 145 26 109 18 331 65 505 105 33 7 94 21 135 31 81 18 340 83 460 116 41
11 98 26 125 33 28 8 73 20 100 29 28 8 84 24 125 36 41 11 116 34 165 49 50
16 135 44 190 61 150 48 462 156 530 185 17 7 64 25 105 40 98 36 364 141 415
164 22 10 85 38 140 61 296 124 845 391 1133 548 59 33 132 73 162 89 171 93
538 313 735 441 626 408 1130 791 1735 1318 315 275 772 733 1161 1164 301
335 738 888 1009 1280 188 272 610 933 610 955 0 3 11 23 25 45 90 143 462
853 544 1040 14 30 47 102 74 160 168 358 443 1062 562 1440 61 193 88 279
100 320 7 25 33 115 58 200 25 85 52 182 60 215 9 33 29 112 45 175 16 63 44
176 61 250 18 74 36 153 42 175 5 22 13 60 18 85 5 25 18 90 29 145 11 55 28
141 37 190 9 50 23 124 31 165 49 262 124 810 150 1105 48 556 59 790 59 1320
0 520 -18 927 -60 1330 -60 575 -128 1030 -220 1470 -36 173 -45 213 -61 280
-8 39 -20 88 -25 110 -4 22 -29 121 -54 220 -26 99 -51 198 -56 220 -5 22 -21
78 -35 125 -13 47 -32 110 -40 140 -19 66 -67 224 -101 335 -42 135 -153 462
-178 525 -7 19 -36 96 -63 170 -175 475 -498 1188 -745 1645 -24 44 -65 121
-92 170 -275 511 -742 1230 -1109 1709 -208 270 -510 639 -657 801 -19 22 -78
87 -130 145 -327 363 -824 848 -1194 1165 -44 37 -94 80 -110 94 -51 44 -197
165 -250 207 -27 21 -59 46 -70 55 -524 425 -1298 946 -1900 1279 -33 18 -76
42 -95 53 -154 87 -701 365 -815 414 -27 12 -93 42 -145 66 -461 216 -1268
515 -1815 672 -80 23 -110 32 -170 49 -65 20 -430 114 -515 133 -36 8 -90 21
-120 28 -146 34 -455 99 -550 115 -36 7 -99 18 -140 26 -143 26 -422 70 -540
84 -47 6 -92 12 -100 15 -8 2 -60 9 -115 15 -55 6 -158 18 -230 26 -321 36
-643 58 -1090 74 -319 11 -446 11 -790 -1z m950 -1249 c563 -25 975 -65 1405
-134 47 -8 128 -21 180 -29 140 -23 251 -44 380 -71 63 -13 140 -29 170 -35
30 -7 73 -16 95 -21 22 -5 76 -16 120 -26 72 -15 169 -39 325 -81 190 -50 240
-64 285 -77 28 -8 68 -20 90 -26 22 -6 72 -22 110 -34 39 -13 117 -38 175 -56
58 -18 146 -46 195 -62 101 -32 380 -135 407 -149 10 -5 23 -9 29 -9 6 0 25
-6 42 -13 18 -8 70 -29 117 -46 101 -39 289 -115 345 -141 22 -10 69 -30 105
-45 36 -15 99 -43 140 -62 41 -19 116 -53 165 -76 50 -23 207 -100 350 -173
251 -127 305 -155 404 -211 25 -14 84 -47 131 -73 47 -26 150 -87 230 -135 80
-49 154 -93 165 -99 11 -6 31 -19 45 -27 14 -9 72 -47 130 -84 147 -94 391
-258 482 -324 42 -31 86 -63 99 -71 33 -22 182 -132 196 -144 16 -14 25 -21
98 -76 80 -60 289 -227 379 -304 40 -33 95 -79 122 -101 55 -45 194 -167 269
-236 28 -25 84 -77 126 -115 42 -38 195 -188 341 -334 318 -318 497 -512 736
-795 52 -60 102 -120 112 -132 55 -65 228 -284 310 -393 52 -69 97 -127 100
-130 4 -3 28 -36 55 -75 26 -38 71 -101 100 -140 155 -211 496 -745 638 -1000
200 -358 404 -751 510 -985 13 -30 39 -86 56 -123 17 -38 61 -139 97 -225 36
-86 72 -170 79 -187 7 -16 32 -79 55 -140 24 -60 49 -124 56 -142 8 -17 14
-36 14 -42 0 -6 4 -19 9 -29 14 -27 117 -307 149 -407 16 -49 44 -137 62 -195
18 -58 48 -152 67 -210 19 -58 41 -132 49 -165 16 -60 38 -143 54 -200 25 -89
74 -278 87 -330 13 -59 25 -112 57 -265 8 -41 22 -106 31 -145 27 -120 51
-255 101 -560 8 -49 19 -110 24 -135 5 -25 11 -76 15 -115 3 -38 10 -99 15
-135 101 -704 127 -1638 70 -2510 -17 -271 -77 -807 -115 -1030 -28 -166 -45
-273 -55 -335 -12 -72 -46 -239 -86 -420 -7 -33 -20 -91 -28 -130 -28 -126
-45 -199 -71 -295 -49 -180 -87 -325 -97 -365 -9 -39 -48 -166 -73 -240 -7
-22 -34 -107 -60 -190 -26 -82 -51 -159 -55 -170 -5 -11 -18 -47 -29 -80 -11
-34 -24 -72 -30 -85 -5 -14 -42 -113 -81 -220 -65 -177 -139 -365 -176 -447
-8 -17 -14 -34 -14 -37 0 -3 -11 -29 -24 -58 -55 -120 -86 -190 -86 -194 0 -2
-18 -41 -39 -87 -22 -45 -49 -104 -61 -132 -29 -65 -208 -424 -262 -523 -22
-42 -59 -111 -81 -152 -95 -182 -328 -574 -447 -755 -33 -49 -63 -97 -67 -105
-9 -18 -156 -235 -251 -371 -233 -334 -589 -782 -881 -1109 -467 -523 -1065
-1092 -1560 -1483 -47 -37 -95 -76 -107 -86 -151 -131 -764 -564 -1042 -737
-35 -21 -74 -46 -87 -54 -20 -12 -89 -55 -240 -147 -11 -7 -27 -15 -35 -19 -8
-4 -31 -18 -51 -30 -33 -20 -147 -85 -274 -155 -289 -158 -754 -387 -987 -485
-35 -14 -81 -35 -103 -45 -108 -49 -405 -169 -418 -169 -9 0 -18 -4 -22 -9 -3
-5 -20 -13 -37 -17 -18 -3 -39 -10 -48 -14 -8 -5 -91 -36 -185 -70 -93 -34
-179 -65 -190 -70 -11 -5 -87 -29 -170 -55 -82 -25 -168 -52 -190 -60 -64 -21
-207 -67 -240 -76 -47 -13 -132 -35 -235 -62 -52 -14 -149 -39 -215 -57 -66
-17 -151 -38 -190 -46 -38 -8 -97 -21 -130 -28 -33 -8 -96 -21 -140 -31 -44
-9 -111 -23 -150 -32 -121 -26 -207 -41 -440 -79 -307 -49 -549 -79 -835 -103
-74 -7 -184 -16 -245 -22 -60 -5 -283 -17 -495 -26 -326 -13 -443 -14 -765 -4
-407 12 -812 39 -1085 71 -41 5 -136 16 -210 24 -133 15 -236 30 -415 60 -49
8 -133 22 -186 30 -52 8 -151 27 -220 41 -68 15 -155 34 -194 42 -38 8 -99 21
-135 29 -36 7 -90 19 -120 25 -30 6 -80 17 -110 25 -61 17 -228 60 -395 104
-147 38 -178 47 -260 75 -38 12 -137 44 -220 70 -215 68 -250 79 -345 115 -47
17 -96 35 -110 40 -14 4 -99 36 -190 70 -209 78 -281 107 -422 169 -26 12 -50
21 -53 21 -3 0 -30 11 -58 24 -29 14 -95 43 -147 66 -195 85 -658 311 -825
403 -33 18 -94 51 -135 73 -299 161 -691 403 -1070 662 -194 132 -667 483
-690 511 -3 4 -25 22 -50 41 -40 30 -118 95 -300 249 -366 309 -889 820 -1210
1180 -41 47 -97 110 -125 141 -27 31 -59 68 -71 82 -61 73 -277 335 -287 348
-7 8 -39 49 -72 90 -244 303 -594 811 -854 1239 -79 128 -145 241 -149 250 -3
9 -23 44 -43 77 -21 34 -66 115 -100 180 -34 66 -65 124 -69 129 -11 14 -211
416 -248 495 -16 36 -45 99 -65 140 -64 137 -89 194 -154 350 -35 85 -70 169
-78 185 -14 33 -163 425 -176 465 -4 14 -22 63 -39 110 -36 95 -47 130 -115
345 -26 83 -58 182 -71 220 -20 62 -41 136 -89 320 -7 28 -27 104 -45 170 -38
143 -62 241 -84 350 -10 44 -21 96 -26 115 -9 35 -26 114 -54 250 -17 84 -34
177 -47 260 -4 30 -15 98 -24 150 -28 169 -53 343 -60 420 -3 41 -10 100 -15
130 -31 192 -66 665 -81 1105 -20 570 14 1342 82 1855 5 41 14 116 19 165 10
91 25 197 55 380 15 90 24 140 55 325 5 30 16 87 25 125 15 67 22 100 60 280
40 191 45 213 120 490 29 108 67 249 75 280 7 30 50 166 75 240 8 22 35 108
60 190 26 83 50 159 55 170 4 11 29 79 55 150 26 72 54 144 61 162 8 17 14 38
14 46 0 9 5 19 10 22 6 3 10 14 10 23 0 9 7 30 15 46 8 15 15 33 15 38 0 8 96
246 130 323 45 101 63 144 85 196 14 33 39 90 56 127 17 37 43 92 56 122 104
228 337 681 476 920 33 58 71 125 84 149 119 212 422 677 606 931 97 134 320
432 348 465 10 11 46 56 81 100 142 181 341 412 522 610 132 144 488 513 523
544 16 13 78 71 139 130 410 394 778 699 1359 1126 81 59 365 251 546 368 194
126 538 328 745 438 55 29 104 56 109 60 12 9 493 249 555 277 25 11 85 39
133 61 49 23 90 41 92 41 3 0 28 11 57 24 125 57 190 86 195 86 3 0 19 6 36
14 78 35 265 109 407 161 85 31 171 62 190 70 98 38 271 98 405 140 83 25 204
64 271 85 66 22 138 44 160 49 46 12 147 38 189 51 153 44 316 84 465 115 105
21 210 44 285 60 141 31 221 47 275 56 60 9 195 31 335 55 386 65 871 112
1390 134 242 10 844 10 1070 0z"/>
<path d="M15830 28899 c-232 -9 -505 -26 -610 -39 -47 -5 -152 -17 -235 -25
-203 -21 -529 -64 -640 -85 -27 -5 -70 -12 -95 -15 -25 -3 -65 -10 -90 -15
-25 -5 -85 -16 -135 -25 -49 -9 -119 -22 -155 -30 -36 -7 -101 -21 -145 -30
-44 -9 -98 -20 -120 -25 -22 -6 -80 -19 -130 -30 -49 -11 -106 -24 -125 -30
-19 -5 -73 -18 -120 -30 -85 -21 -255 -66 -360 -96 -30 -9 -77 -22 -105 -29
-27 -7 -86 -25 -130 -39 -44 -14 -123 -38 -175 -55 -173 -54 -443 -149 -507
-177 -17 -8 -38 -14 -46 -14 -9 0 -18 -4 -22 -9 -3 -5 -20 -13 -37 -17 -18 -3
-39 -10 -48 -15 -8 -5 -62 -27 -120 -50 -130 -51 -457 -190 -525 -222 -27 -13
-79 -37 -115 -53 -94 -42 -565 -277 -657 -328 -143 -79 -168 -93 -228 -126
-33 -18 -76 -43 -95 -55 -19 -12 -57 -34 -85 -49 -43 -24 -322 -193 -389 -236
-13 -8 -52 -33 -87 -55 -35 -21 -68 -43 -74 -48 -5 -4 -39 -27 -75 -50 -497
-324 -1071 -778 -1600 -1266 -232 -214 -659 -650 -810 -826 -24 -27 -46 -52
-49 -55 -6 -5 -114 -131 -227 -265 -448 -529 -979 -1303 -1321 -1922 -12 -24
-35 -64 -50 -90 -94 -169 -288 -552 -380 -753 -19 -41 -45 -98 -58 -126 -13
-28 -56 -127 -95 -220 -39 -93 -75 -178 -80 -189 -4 -11 -22 -56 -40 -100 -17
-44 -37 -93 -44 -110 -8 -16 -21 -52 -31 -80 -9 -27 -21 -59 -25 -70 -4 -11
-15 -38 -23 -60 -29 -73 -75 -208 -130 -375 -91 -279 -100 -308 -150 -485 -3
-11 -13 -45 -22 -75 -23 -78 -73 -271 -96 -365 -11 -44 -24 -96 -29 -115 -6
-19 -15 -55 -20 -80 -5 -25 -16 -79 -25 -120 -46 -207 -43 -194 -80 -385 -27
-137 -70 -399 -90 -545 -39 -278 -54 -413 -74 -635 -6 -66 -16 -169 -21 -230
-6 -60 -17 -293 -25 -517 -13 -360 -13 -456 0 -820 8 -227 19 -460 25 -518 5
-58 15 -159 21 -225 26 -288 61 -575 103 -830 8 -49 19 -121 26 -160 12 -77
53 -293 74 -395 8 -36 22 -101 31 -145 9 -44 20 -96 25 -115 5 -19 30 -120 55
-225 26 -104 52 -210 59 -235 8 -25 21 -74 31 -110 9 -36 21 -76 25 -90 5 -14
16 -52 25 -85 9 -33 23 -78 30 -100 7 -22 27 -87 45 -145 18 -58 48 -150 67
-205 19 -55 45 -131 58 -170 23 -71 158 -433 174 -470 5 -11 23 -56 41 -100
18 -44 36 -89 41 -100 20 -49 52 -122 64 -150 7 -16 31 -70 52 -120 68 -155
114 -251 256 -535 129 -258 153 -305 235 -452 18 -32 46 -84 63 -115 17 -31
56 -96 85 -145 30 -49 54 -91 54 -94 0 -4 14 -26 30 -51 17 -24 30 -47 30 -50
0 -3 10 -20 23 -37 13 -17 45 -67 72 -111 43 -71 229 -354 282 -431 236 -339
499 -687 693 -917 170 -202 313 -366 440 -502 84 -91 604 -611 695 -695 39
-36 111 -101 160 -145 50 -44 92 -82 95 -85 3 -3 48 -41 100 -85 52 -44 97
-82 100 -85 3 -3 73 -59 155 -125 83 -66 152 -123 155 -126 3 -3 61 -47 130
-99 69 -52 127 -96 130 -100 3 -3 28 -22 55 -40 28 -19 55 -38 60 -42 88 -73
537 -377 735 -498 58 -35 107 -67 110 -70 3 -4 12 -10 20 -13 8 -4 29 -16 45
-27 17 -11 37 -23 45 -27 8 -4 54 -31 102 -60 48 -29 116 -68 150 -86 35 -19
85 -47 113 -62 154 -87 657 -341 800 -405 28 -12 82 -37 120 -55 39 -18 115
-52 170 -75 55 -23 118 -50 140 -60 60 -27 284 -116 420 -167 163 -61 184 -68
305 -109 58 -19 114 -39 125 -44 11 -5 49 -18 85 -29 36 -11 108 -34 160 -51
52 -17 118 -38 145 -45 28 -8 74 -21 103 -30 134 -39 164 -48 267 -75 194 -51
218 -57 320 -80 55 -12 111 -25 125 -30 14 -5 52 -13 85 -19 33 -6 92 -18 130
-26 39 -8 108 -22 155 -31 47 -8 108 -20 135 -25 152 -29 361 -63 510 -83 298
-42 455 -59 855 -96 63 -6 297 -17 520 -25 354 -13 456 -13 815 0 226 8 457
19 515 25 58 5 179 17 270 25 180 17 378 40 480 55 36 6 99 15 140 20 41 6
116 16 165 24 50 8 118 19 152 25 34 5 86 14 115 20 29 6 91 18 138 26 47 9
114 22 150 30 36 7 101 21 145 30 163 34 402 92 622 151 29 8 77 21 105 29 29
7 77 21 106 29 28 9 73 22 100 30 133 40 332 105 582 191 104 36 156 55 217
81 17 8 38 14 46 14 9 0 19 4 22 10 3 5 13 10 22 10 8 0 29 6 46 14 18 8 68
29 112 46 44 18 94 38 112 46 17 8 33 14 36 14 3 0 36 14 74 31 37 16 77 34
88 39 11 5 51 23 88 39 38 17 70 31 72 31 2 0 43 18 92 41 48 22 108 50 133
61 62 28 543 268 555 277 6 5 54 31 109 60 198 106 565 320 726 426 309 202
458 303 630 428 87 64 324 241 330 247 3 3 52 43 110 88 439 349 702 583 1090
972 267 267 455 470 642 690 25 30 75 89 110 130 124 145 420 524 527 675 115
163 190 269 229 327 377 553 766 1245 1022 1813 12 28 31 68 42 90 27 56 140
323 205 485 29 74 58 144 62 155 5 11 21 54 36 95 84 234 108 302 131 370 14
41 29 84 34 95 5 11 23 67 40 125 17 58 36 117 41 132 6 14 15 41 19 60 5 18
16 57 24 86 50 166 45 147 99 352 14 52 28 104 31 115 3 11 15 61 26 110 11
50 25 106 30 125 11 39 30 126 55 245 9 44 23 109 30 145 8 36 21 103 29 150
8 47 17 99 20 115 18 91 63 378 76 485 5 41 14 104 19 140 6 36 17 135 26 220
9 85 20 200 26 255 17 160 27 345 40 737 16 458 -2 1086 -40 1476 -24 232 -42
398 -52 467 -5 39 -14 104 -19 145 -14 109 -61 407 -84 530 -8 41 -19 102 -25
135 -6 33 -18 92 -26 130 -9 39 -23 104 -31 145 -34 158 -64 287 -89 385 -9
33 -20 76 -24 95 -11 45 -46 175 -72 265 -11 39 -27 95 -35 125 -9 30 -19 66
-24 80 -4 14 -23 72 -40 130 -42 136 -89 280 -100 305 -5 11 -25 67 -44 125
-19 58 -44 130 -56 160 -12 30 -28 75 -37 100 -14 42 -124 321 -143 365 -5 11
-18 43 -30 70 -12 28 -25 59 -30 70 -5 11 -18 43 -30 70 -30 71 -47 107 -141
313 -143 310 -473 933 -589 1112 -6 8 -13 22 -17 30 -3 8 -25 44 -48 80 -23
36 -67 106 -98 155 -133 215 -279 432 -451 669 -142 195 -285 387 -295 396 -4
3 -42 50 -86 105 -71 90 -271 332 -285 345 -3 3 -54 61 -115 130 -230 262
-590 631 -845 865 -41 38 -77 72 -80 75 -3 3 -45 41 -95 85 -49 44 -99 88
-110 98 -38 35 -110 96 -245 207 -74 62 -144 119 -155 128 -11 10 -42 35 -70
57 -27 22 -52 43 -55 46 -9 10 -247 188 -418 311 -276 200 -469 327 -862 568
-80 49 -220 130 -350 202 -25 14 -70 39 -100 56 -158 88 -538 280 -715 362
-33 15 -95 44 -138 64 -43 20 -80 36 -82 36 -2 0 -34 14 -72 31 -90 40 -100
44 -158 69 -78 33 -85 36 -170 70 -44 18 -89 36 -100 41 -34 15 -315 119 -379
140 -34 11 -70 24 -81 29 -33 14 -435 146 -490 161 -16 4 -79 22 -140 39 -60
17 -132 37 -160 45 -27 7 -95 25 -150 40 -55 14 -129 33 -165 42 -36 8 -91 21
-122 29 -32 8 -86 21 -120 29 -35 8 -85 19 -113 25 -27 5 -93 18 -145 30 -52
11 -122 25 -155 31 -33 6 -94 17 -135 25 -145 28 -382 65 -535 84 -44 6 -109
14 -145 20 -36 5 -132 16 -215 25 -82 9 -190 20 -240 26 -358 40 -1119 58
-1605 38z m-1087 -3229 l34 -30 1319 -1 c726 -1 1429 -6 1564 -11 223 -8 247
-10 270 -28 14 -12 40 -30 58 -41 18 -12 37 -30 42 -40 7 -12 10 -585 10
-1654 l0 -1635 22 -9 c13 -4 59 -11 103 -15 107 -9 160 -24 189 -51 22 -20 40
-23 210 -34 102 -6 197 -16 210 -21 14 -5 32 -21 42 -35 14 -22 23 -25 78 -25
33 0 102 -5 151 -10 72 -9 100 -17 140 -41 41 -26 70 -34 164 -47 108 -14 117
-17 148 -49 26 -25 42 -33 71 -33 69 -1 123 -17 163 -49 28 -23 56 -35 101
-42 78 -13 125 -32 132 -56 5 -15 23 -22 80 -31 55 -10 79 -19 96 -37 17 -18
34 -25 61 -25 52 0 153 -45 170 -76 9 -17 29 -29 66 -40 29 -8 70 -30 92 -48
28 -24 54 -35 90 -40 40 -5 58 -15 91 -46 23 -22 58 -44 78 -50 46 -12 56 -19
71 -47 6 -13 19 -23 29 -23 33 -1 102 -39 113 -64 7 -15 28 -31 55 -41 31 -12
46 -24 50 -41 4 -16 15 -26 33 -29 41 -9 95 -40 105 -62 5 -11 28 -27 50 -35
25 -9 49 -27 61 -46 20 -31 26 -35 77 -46 20 -5 30 -15 36 -38 6 -24 15 -34
39 -40 18 -5 41 -21 52 -37 11 -16 44 -40 73 -55 31 -15 61 -40 73 -58 12 -20
33 -36 54 -42 24 -7 37 -19 47 -41 7 -18 24 -39 38 -48 14 -9 31 -29 38 -44 8
-16 26 -31 43 -36 49 -15 59 -22 66 -51 5 -16 17 -35 29 -43 11 -8 27 -27 35
-44 9 -16 31 -35 50 -44 20 -8 38 -22 41 -32 13 -40 30 -66 46 -71 9 -2 33
-31 54 -63 20 -33 50 -69 65 -80 15 -10 30 -33 34 -49 5 -22 14 -32 33 -36 40
-10 52 -23 52 -56 0 -22 8 -33 34 -49 22 -13 39 -33 46 -55 6 -18 21 -42 34
-51 24 -20 66 -99 66 -126 0 -10 15 -23 35 -31 21 -9 39 -26 45 -42 6 -15 24
-42 39 -60 27 -30 41 -62 55 -127 4 -16 17 -32 31 -39 19 -9 30 -28 47 -85 13
-42 27 -73 35 -73 25 0 50 -38 57 -85 6 -35 17 -57 42 -83 19 -20 37 -47 40
-61 14 -63 27 -90 50 -105 13 -9 35 -34 49 -56 20 -32 23 -45 15 -60 -5 -10
-10 -35 -10 -55 0 -31 4 -37 29 -46 33 -12 46 -41 54 -125 4 -36 15 -65 37
-96 23 -32 35 -65 46 -128 9 -53 21 -91 34 -105 25 -26 49 -118 59 -228 6 -66
13 -93 28 -110 31 -33 42 -79 57 -240 8 -81 19 -153 24 -160 6 -7 13 -30 17
-52 4 -22 14 -53 22 -68 27 -53 39 -470 35 -1172 -4 -502 -18 -678 -65 -778
-20 -42 -26 -75 -32 -177 -8 -150 -18 -196 -52 -232 -22 -24 -26 -38 -34 -142
-11 -146 -15 -164 -49 -181 -29 -15 -40 -47 -40 -119 0 -61 -23 -140 -54 -184
-15 -22 -35 -74 -45 -116 -12 -51 -27 -87 -44 -104 -15 -17 -30 -50 -38 -87
-10 -43 -22 -69 -46 -94 -18 -19 -33 -44 -33 -55 0 -52 -22 -98 -56 -118 -27
-16 -34 -26 -34 -51 0 -59 -20 -108 -50 -125 -22 -13 -32 -28 -39 -62 -16 -76
-29 -99 -56 -106 -17 -4 -27 -15 -31 -33 -23 -109 -30 -125 -55 -140 -16 -9
-32 -30 -38 -52 -8 -26 -20 -40 -41 -49 -19 -8 -33 -22 -36 -39 -8 -31 -40
-87 -68 -119 -11 -13 -25 -37 -31 -55 -6 -21 -21 -37 -42 -47 -23 -10 -36 -25
-43 -49 -5 -18 -27 -54 -48 -80 -21 -25 -47 -61 -56 -79 -9 -18 -28 -37 -41
-42 -14 -5 -33 -26 -42 -47 -10 -21 -29 -43 -43 -50 -16 -8 -29 -26 -36 -50
-8 -30 -17 -40 -42 -48 -23 -6 -35 -18 -43 -42 -7 -23 -20 -36 -45 -45 -29
-10 -34 -17 -34 -44 0 -35 -30 -79 -60 -89 -11 -3 -45 -33 -76 -65 -30 -32
-68 -63 -84 -70 -16 -7 -38 -27 -49 -45 -13 -22 -30 -35 -50 -40 -23 -5 -35
-16 -46 -40 -10 -25 -22 -36 -46 -43 -26 -6 -35 -15 -41 -40 -7 -26 -16 -33
-49 -42 -31 -9 -43 -18 -51 -41 -7 -20 -22 -35 -47 -45 -20 -8 -47 -26 -61
-40 -14 -14 -41 -38 -59 -54 -29 -23 -33 -31 -23 -43 7 -8 25 -18 40 -22 56
-14 67 -43 27 -69 -23 -15 -27 -15 -65 4 -22 11 -40 25 -40 30 0 12 -90 13
-105 2 -5 -4 -17 -20 -25 -36 -11 -22 -31 -36 -75 -53 -33 -13 -68 -34 -78
-48 -10 -14 -33 -27 -52 -31 -22 -4 -43 -18 -60 -40 -24 -31 -48 -42 -127 -59
-16 -3 -28 -11 -28 -19 0 -20 -31 -44 -78 -60 -23 -8 -54 -27 -69 -41 -16 -14
-52 -33 -81 -41 -43 -13 -56 -21 -64 -44 -11 -33 -20 -38 -106 -50 -52 -7 -71
-15 -97 -40 -25 -24 -49 -35 -105 -46 -59 -12 -79 -22 -104 -48 -25 -27 -44
-35 -95 -44 -45 -8 -70 -18 -86 -36 -27 -29 -73 -43 -173 -52 -66 -6 -74 -10
-89 -35 -21 -36 -25 -37 -145 -51 -90 -10 -100 -13 -118 -38 -16 -22 -32 -29
-89 -38 -38 -7 -87 -12 -108 -12 -38 0 -56 -8 -106 -43 -27 -19 -172 -47 -247
-47 -29 0 -67 -11 -108 -30 -77 -36 -147 -49 -322 -56 -111 -5 -144 -10 -185
-29 -78 -36 -165 -45 -520 -56 -237 -7 -5684 -23 -5950 -17 -16 0 -52 0 -80 0
-185 -2 -183 -2 -221 -27 -28 -19 -40 -22 -55 -14 -10 5 -19 13 -19 18 0 4
-23 30 -51 57 l-50 49 4 58 c2 39 10 67 26 87 21 29 22 39 18 148 -2 64 -9
145 -17 180 -12 56 -12 66 3 97 16 33 17 413 17 5631 0 5581 0 5596 -20 5621
-26 33 -25 45 3 70 53 49 -24 47 1555 44 816 -1 1555 1 1643 5 l159 7 2 1642
c3 1565 4 1644 21 1663 10 11 35 22 55 26 26 4 45 15 60 36 13 16 25 30 27 30
2 0 20 -13 38 -30z m3322 -19 c7 -12 -12 -24 -25 -16 -11 7 -4 25 10 25 5 0
11 -4 15 -9z m1400 -3600 c8 -14 -9 -21 -35 -14 -22 6 -23 7 -4 14 26 10 32
10 39 0z m1529 -706 c0 -5 -12 -14 -27 -19 -30 -11 -55 4 -42 24 8 13 70 9 69
-5z m505 -163 c19 -36 -26 -56 -64 -29 -17 13 -17 15 6 30 30 22 46 21 58 -1z
m598 -548 c-9 -9 -28 6 -21 18 4 6 10 6 17 -1 6 -6 8 -13 4 -17z m103 -69 c10
-12 10 -18 0 -30 -17 -20 -37 -19 -49 3 -18 34 24 57 49 27z m417 -717 c-3 -8
-6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m973 -1618 c0 -27 -4 -50 -10
-50 -11 0 -14 83 -3 93 12 12 13 8 13 -43z m-957 -5740 c6 -23 -7 -34 -28 -26
-12 4 -13 10 -3 30 12 28 23 27 31 -4z m-432 -171 c9 -17 8 -25 -4 -37 -19
-18 -47 -9 -47 16 0 18 18 42 32 42 4 0 12 -10 19 -21z m-276 -289 c-3 -5 -10
-10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z m-255 -245 c10
-13 10 -19 -5 -35 -16 -18 -19 -18 -41 -3 -30 19 -30 27 -1 41 30 14 33 14 47
-3z m-1506 -1345 c-6 -6 -15 -6 -24 0 -13 9 -13 11 0 20 18 11 39 -5 24 -20z
m-7569 -1386 c82 -22 174 -65 245 -115 33 -23 60 -44 60 -47 0 -10 -175 -222
-184 -222 -4 0 -25 13 -45 29 -205 162 -463 118 -568 -97 -25 -51 -28 -68 -28
-157 0 -93 2 -105 33 -167 78 -159 253 -228 427 -168 78 27 85 34 85 91 l0 49
-105 0 c-125 0 -117 -12 -113 170 l3 125 258 3 257 2 0 -289 0 -290 -29 -21
c-47 -33 -148 -77 -246 -106 -81 -25 -106 -28 -245 -28 -134 -1 -165 2 -225
21 -195 62 -356 222 -416 413 -11 36 -22 105 -25 155 -21 328 207 603 548 665
71 12 239 4 313 -16z m-1360 -14 c184 -58 314 -166 395 -328 124 -251 48 -579
-175 -749 -127 -97 -265 -143 -430 -143 -123 0 -213 21 -323 75 -376 186 -460
693 -161 984 92 91 222 157 353 181 84 15 261 4 341 -20z m3210 -5 c44 -14
103 -40 131 -56 69 -39 154 -108 154 -124 0 -10 -124 -169 -171 -219 -4 -5
-31 12 -61 39 -29 26 -82 62 -118 79 -161 77 -347 11 -427 -153 -25 -49 -28
-67 -28 -151 0 -82 4 -103 27 -152 35 -76 103 -141 176 -168 113 -43 274 -9
374 79 20 17 40 31 45 31 6 0 51 -45 102 -99 l91 -99 -41 -36 c-173 -157 -426
-222 -643 -166 -179 45 -330 166 -404 321 -52 109 -66 172 -66 289 2 251 128
448 356 555 153 72 346 83 503 30z m6624 5 c85 -21 242 -88 255 -109 4 -6 -20
-67 -53 -136 l-60 -126 -61 31 c-80 41 -222 87 -291 95 -141 17 -215 -62 -131
-140 18 -18 77 -42 187 -76 198 -63 268 -96 324 -151 74 -74 86 -106 86 -233
0 -95 -3 -116 -23 -152 -50 -94 -127 -155 -247 -195 -61 -20 -88 -23 -220 -22
-122 0 -165 5 -230 23 -94 25 -245 97 -295 139 l-35 30 60 121 c32 67 63 121
67 121 4 0 30 -16 57 -35 116 -83 282 -139 398 -133 90 4 126 27 131 81 6 75
-20 93 -246 161 -85 26 -176 58 -204 72 -157 80 -220 226 -169 394 19 62 31
82 79 130 103 102 229 143 422 135 68 -2 149 -13 199 -25z m-11291 -457 l2
-473 253 -2 252 -3 3 -137 3 -138 -421 0 -420 0 0 615 0 615 163 -2 162 -3 3
-472z m3660 -140 l2 -613 -165 0 -165 0 0 608 c0 335 3 612 7 616 4 3 77 5
162 4 l156 -3 3 -612z m2462 606 c98 -15 191 -51 269 -103 166 -111 253 -283
253 -501 0 -210 -82 -380 -236 -490 -151 -108 -240 -125 -642 -125 l-304 0 0
608 c0 335 3 612 7 615 11 11 582 8 653 -4z m1627 -594 c139 -330 252 -606
252 -612 1 -10 -39 -13 -168 -13 l-169 0 -42 105 -42 105 -262 0 -263 0 -40
-102 -41 -103 -166 -3 c-91 -1 -166 0 -166 4 0 3 118 280 261 615 l261 609
167 -2 166 -3 252 -600z m1163 470 l0 -135 -180 0 -180 0 -2 -477 -3 -478
-162 -3 -163 -2 0 480 0 480 -180 0 -180 0 0 135 0 135 525 0 525 0 0 -135z
m709 118 c19 -36 501 -1193 501 -1203 0 -6 -59 -10 -169 -10 l-170 0 -39 103
-40 102 -264 3 -265 2 -40 -102 -41 -103 -167 -3 c-147 -2 -167 -1 -161 13 3
8 121 285 262 615 l257 600 163 0 c145 0 164 -2 173 -17z m2383 -380 c3 -386
4 -399 25 -438 45 -84 114 -125 210 -125 94 1 170 48 206 129 8 18 14 156 17
426 l5 400 160 0 160 0 3 -383 c3 -423 -3 -476 -58 -588 -57 -117 -196 -219
-339 -250 -85 -18 -228 -18 -314 1 -189 40 -339 182 -388 365 -12 47 -26 817
-15 845 4 12 34 15 165 15 l160 0 3 -397z m1723 382 c202 -38 306 -141 306
-303 0 -49 -6 -85 -19 -116 -23 -50 -88 -118 -131 -136 l-30 -12 59 -30 c108
-54 161 -145 161 -278 0 -68 -4 -91 -26 -135 -47 -96 -142 -161 -282 -190 -52
-11 -151 -15 -415 -15 l-348 0 0 615 0 615 324 0 c244 0 343 -4 401 -15z"/>
<path d="M15273 22143 l-572 -3 -4 -188 c-2 -103 -4 -1705 -5 -3559 l-1 -3373
22 -5 c12 -3 1489 -4 3282 -3 l3260 3 22 22 c12 12 28 43 35 70 10 34 22 54
45 68 27 18 31 26 37 86 8 87 22 128 57 162 23 22 29 37 30 70 0 23 4 108 8
189 8 135 11 150 36 194 26 44 29 60 36 194 12 200 -1 367 -38 510 -22 83 -32
151 -37 245 -6 109 -11 136 -29 165 -40 62 -48 91 -59 208 -10 102 -14 115
-34 124 -27 13 -44 48 -51 110 -3 26 -12 53 -21 60 -19 16 -12 16 -1757 17
-808 0 -1483 1 -1498 1 -40 1 -78 22 -116 65 -26 30 -31 44 -31 88 0 2545 -7
4368 -16 4377 -17 17 -148 43 -223 45 -51 2 -68 6 -81 22 -11 14 -33 23 -66
27 -59 7 -1433 13 -2231 9z m5877 -4743 c13 -9 12 -12 -4 -25 -24 -18 -26 -18
-51 1 -17 13 -18 16 -5 24 19 12 41 12 60 0z"/>
<path d="M10955 8781 c-99 -27 -175 -88 -221 -178 -26 -53 -29 -67 -29 -158 0
-84 4 -107 24 -147 33 -68 91 -129 152 -163 49 -27 63 -30 144 -30 74 0 98 4
137 23 63 31 121 91 155 160 25 51 28 68 28 157 0 82 -4 109 -22 147 -31 68
-104 142 -166 168 -56 24 -153 34 -202 21z"/>
<path d="M15570 8444 l0 -346 153 4 c147 3 154 4 205 33 64 35 96 71 131 145
23 48 26 68 26 161 0 101 -2 109 -35 175 -66 129 -160 174 -363 174 l-117 0 0
-346z"/>
<path d="M17017 8509 c-44 -110 -77 -203 -74 -205 2 -2 74 -3 159 -2 l155 3
-74 195 c-41 107 -77 198 -81 202 -4 4 -42 -83 -85 -193z"/>
<path d="M19143 8522 c-40 -103 -76 -195 -79 -204 -6 -17 6 -18 156 -18 89 0
160 4 158 8 -1 5 -37 97 -78 205 -41 108 -77 197 -80 197 -3 0 -38 -85 -77
-188z"/>
<path d="M23100 8685 l0 -115 140 0 c159 0 199 11 224 59 20 38 20 90 0 119
-31 44 -63 52 -219 52 l-145 0 0 -115z"/>
<path d="M23102 8208 l3 -123 155 0 c122 1 161 4 185 17 95 50 99 159 8 204
-31 15 -66 19 -196 22 l-158 4 3 -124z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
